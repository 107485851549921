
  import { Component, Vue } from "vue-property-decorator";
  import { namespace } from "vuex-class";
  import { apiNFT } from "../api";
  import { NFTForm } from "../components";
  import LzButton from "@/components/Button.vue";

  const auth = namespace("auth");
  @Component({
    components: { NFTForm, LzButton }
  })
  export default class Create extends Vue {
    @auth.State("id")
    public ongId!: string;

    async handleOnSubmit(formValue: NFTFormValues) {
      try {
        const {
          NFT: { id }
        } = await apiNFT.createNFT(this.ongId, formValue);

        this.$notify({
          type: "success",
          text: this.$tc("NFTs.createNFT.form.status.success")
        });

        this.$router.push({ name: "viewNFT", params: { id } });
      } catch (err) {
        this.$notify({
          type: "error",
          text: this.$tc("common.error.generic")
        });
      }
    }
  }
